import { Dispatch, SetStateAction } from 'react';

import { FocusRingVariants } from '@calm-web/design-system';

import { Arrow, Wrapper } from './styles';

const HomepageCarouselNav = ({
	numSlides,
	currentSlideIndex,
	setCurrentSlideIndex,
	numCardsToShow,
}: {
	numSlides: number;
	currentSlideIndex: number;
	setCurrentSlideIndex: Dispatch<SetStateAction<number>>;
	numCardsToShow: number;
}) => {
	const isAtBeginning = currentSlideIndex === 0;
	const isAtEnd = currentSlideIndex >= numSlides - numCardsToShow;

	const onPrev = () => {
		if (!isAtBeginning) {
			setCurrentSlideIndex(currentSlideIndex - 1);
		}
	};

	const onNext = () => {
		if (!isAtEnd) {
			setCurrentSlideIndex(currentSlideIndex + 1);
		}
	};

	return (
		<Wrapper>
			<li>
				<Arrow
					onPress={onPrev}
					isDisabled={isAtBeginning}
					aria-label="previous"
					focusRingVariant={FocusRingVariants.Dark}
				/>
			</li>
			<li>
				<Arrow
					onPress={onNext}
					isDisabled={isAtEnd}
					aria-label="next"
					focusRingVariant={FocusRingVariants.Dark}
				/>
			</li>
		</Wrapper>
	);
};

export default HomepageCarouselNav;
