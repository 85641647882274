import styled from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

import { minWidth } from '@calm-web/design-system';

import { HEADER_INDEX } from '@/utils/ui/zIndices';

export const HEADER_HEIGHT_MOBILE = '64px';
export const HEADER_HEIGHT_DESKTOP = '100px';

export const Wrapper = styled.header<{
	$isOpaque: boolean;
	isBannerVisible?: boolean;
}>`
	align-items: center;
	background: linear-gradient(180deg, #176293 0%, rgba(23, 98, 147, 0.335419) 71%, rgba(23, 98, 147, 0) 100%);
	display: flex;
	height: ${HEADER_HEIGHT_MOBILE};
	padding: 0 16px;
	position: sticky;
	top: ${ifProp('isBannerVisible', theme('app.mobileBannerHeight'), '0')};
	z-index: ${HEADER_INDEX};

	${minWidth('desktop')} {
		height: ${HEADER_HEIGHT_DESKTOP};
		padding: 0 40px;
	}

	${minWidth('tablet')} {
		top: ${ifProp('isBannerVisible', theme('app.bannerHeight'), '0')};
	}

	&:after {
		background: ${palette('white')};
		bottom: 0;
		content: '';
		left: 0;
		height: ${HEADER_HEIGHT_MOBILE};
		opacity: ${p => (p.$isOpaque ? '1' : '0')};
		position: absolute;
		right: 0;
		top: 0;
		transform: ${p => (p.$isOpaque ? 'translateY(0)' : 'translateY(-100%)')};
		transition: ${theme('animation.transition')};
		z-index: -1;

		${minWidth('desktop')} {
			height: ${HEADER_HEIGHT_DESKTOP};
		}
	}
`;
