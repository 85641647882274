import messages from '@/messages/messages';

import { HeaderNavItem } from '../NavItemLink/types';

export const leftNavItems: HeaderNavItem[] = [
	{ label: messages.sleep, href: '/sleep' },
	{ label: messages.stressAnxiety, href: '/stress-anxiety' },
	{ label: messages.mindfulness, href: '/mindfulness' },
];

export const SCREENING_NAV_ITEM = {
	label: messages.screening,
	href: process.env.NEXT_PUBLIC_SCREENING_URL || 'https://screening.calm.com',
	analyticsName: 'Homepage : NavHeader : Health Screening : Clicked',
};

export const rightNavItems: HeaderNavItem[] = [
	{
		label: messages.calmBusiness,
		href: 'https://business.calm.com',
		queryParams: { utm_campaign: 'homepage_for_business' },
		analyticsName: 'Homepage : NavHeader : For Business : Clicked',
	},
	{
		label: messages.calmHealth,
		href: 'https://calmhealth.com/',
		analyticsName: 'Homepage : NavHeader : Calm Health : Clicked',
	},
];
