import { useContext } from 'react';

import { OfferBannerContext } from '@/components/app/layout/OfferBannerProvider';
import AuthModalContextProvider from '@/context/AuthModalContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { usePinnedHeader } from '@/hooks/ui/usePinnedHeader';
import { useRouterPush } from '@/hooks/utils/useRouterPush';

import HeaderAuthModal from '../HeaderAuthModal';
import { HeaderNavItem } from '../NavItemLink/types';
import NavItems from '../NavItems';
import { Wrapper } from './styles';

const Header = ({ isOpaque = false }: { isOpaque?: boolean }) => {
	const routerPush = useRouterPush();
	const isScrolling = usePinnedHeader();
	const { logEvent } = useAnalytics();
	const { isBannerVisible } = useContext(OfferBannerContext);

	const onNavItemClick = async (navItem: HeaderNavItem) => {
		if (navItem.href) {
			logEvent({
				eventName: navItem.analyticsName ?? 'Homepage : NavHeader : Link : Clicked',
				eventProps: {
					link_url: navItem.href,
					is_pinned: isScrolling,
				},
			});
			await routerPush(navItem.href, navItem.queryParams);
		}
	};

	return (
		<AuthModalContextProvider>
			<Wrapper $isOpaque={isOpaque ? true : isScrolling} isBannerVisible={isBannerVisible}>
				<NavItems isOpaque={isOpaque ? true : isScrolling} onNavItemClick={onNavItemClick} />
			</Wrapper>
			<HeaderAuthModal />
		</AuthModalContextProvider>
	);
};

export default Header;
