import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import { getSlideOffset } from '@/components/app/cards/CardCarousel/utils';

import CarouselNav from '../HomepageCarouselNav';
import { Wrapper, Slide, SlideInner } from './styles';
import { SizeData, SlideItem } from './types';
import { getResponsiveValue, getSlideWidth, getSlideHeight } from './utils';

const HomepageCarousel = ({
	slideItems,
	numSlides: _numSlides,
	slideGap: _slideGap,
}: {
	slideItems: SlideItem[];
	numSlides: SizeData;
	slideGap: SizeData;
}) => {
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	const [slideWidth, setSlideWidth] = useState<number>();
	const [slideHeight, setSlideHeight] = useState<number>();
	const [slideInnerHeight, setSlideInnerHeight] = useState<string>('auto');
	const [isReady, setIsReady] = useState<boolean>(false);

	const { width: winWidth } = useWindowSize();
	const carouselRef = useRef<HTMLUListElement>(null);

	const numCards = getResponsiveValue(_numSlides, winWidth);
	const slideGap = getResponsiveValue(_slideGap, winWidth);

	useEffect(() => {
		if (!carouselRef.current) {
			return;
		}

		const carouselContainer = carouselRef.current;

		const setSlideDimensions = () => {
			setIsReady(false);
			const containerWidth = carouselContainer.getBoundingClientRect().width;
			const width = getSlideWidth(containerWidth, numCards, slideGap);
			setSlideWidth(width);
			const height = getSlideHeight(carouselContainer);
			setSlideHeight(height);
			setIsReady(true);
		};

		const resizeObserver = new ResizeObserver(setSlideDimensions);

		resizeObserver.observe(carouselContainer);

		return () => {
			resizeObserver.disconnect();
		};
	}, [numCards, slideGap]);

	// Set slide inner height to 100% to fill container after dimensions are set on slides
	// Needs a timeout so that dimensions can be properly calculated
	useEffect(() => {
		if (isReady) {
			setTimeout(() => {
				setSlideInnerHeight('100%');
			}, 250);
		} else {
			setSlideInnerHeight('auto');
		}
	}, [isReady]);

	return (
		<>
			<Wrapper $height={slideHeight ? `${slideHeight}px` : 'auto'} ref={carouselRef}>
				{slideItems.map((item, index) => {
					const isActive = currentSlideIndex <= index && currentSlideIndex > index - numCards;
					return (
						<Slide
							aria-hidden={!isActive}
							$width={slideWidth ? `${slideWidth}px` : 'auto'}
							$height={slideHeight ? `${slideHeight}px` : 'auto'}
							key={item.key}
							$leftOffset={getSlideOffset(index, currentSlideIndex, slideGap)}
						>
							<SlideInner $height={slideInnerHeight}>
								<item.Slide isActive={isActive} />
							</SlideInner>
						</Slide>
					);
				})}
			</Wrapper>
			<CarouselNav
				numSlides={slideItems.length}
				currentSlideIndex={currentSlideIndex}
				setCurrentSlideIndex={setCurrentSlideIndex}
				numCardsToShow={numCards}
			/>
		</>
	);
};

export default HomepageCarousel;
