import { defineMessages } from 'react-intl';

export default defineMessages({
	continueInApp: {
		defaultMessage: 'Continue in app',
		id: 'appInstallActionSheet.continueInApp',
		description: 'Link that sends the user to either or mobile app or the app store',
	},
	continueInBrowser: {
		defaultMessage: 'Continue in browser',
		id: 'appInstallActionSheet.continueInBrowser',
		description: 'Link that redirects the user to our web app',
	},
	safariLogoAriaLabel: {
		defaultMessage: 'Safari Logo',
		id: 'appInstallActionSheet.safariLogoAriaLabel',
		description: 'Aria label for the Safari logo',
	},
	chromeLogoAriaLabel: {
		defaultMessage: 'Chrome Logo',
		id: 'appInstallActionSheet.chromeLogoAriaLabel',
		description: 'Aria label for the Chrome logo',
	},
	browserLogoAriaLabel: {
		defaultMessage: 'Browser Logo',
		id: 'appInstallActionSheet.browserLogoAriaLabel',
		description: 'Aria label for the Browser logo',
	},
	dialogAriaLabel: {
		defaultMessage: 'Dialog to continue with web or redirect to mobile app experience',
		id: 'appInstallActionSheet.dialogAriaLabel',
		description: 'Aria label for the app install action sheet dialog',
	},
});
