import { CalmTheme } from '@calm-web/design-system';

import { SizeData } from './types';

export const getResponsiveValue = (sizes: SizeData, winWidth: number) => {
	if (winWidth >= CalmTheme.breakpoints.desktopLarge) {
		return sizes.desktopLarge;
	}
	if (winWidth >= CalmTheme.breakpoints.desktop) {
		return sizes.desktop;
	}
	if (winWidth >= CalmTheme.breakpoints.tablet) {
		return sizes.tablet;
	}
	return sizes.mobile;
};

export const getSlideWidth = (containerWidth: number, numCards: number, slideGap: number): number => {
	const width = (containerWidth - slideGap * (numCards - 1)) / numCards;
	return width;
};

export const getSlideHeight = (carousel: HTMLUListElement) => {
	const slideHeights = [...carousel.children].map(slide => slide.children[0].getBoundingClientRect().height);

	return Math.max(...slideHeights);
};
