import { ParsedUrlQueryInput } from 'querystring';
import { useIntl } from 'react-intl';

import { adoptOrphans, FocusRingVariants, FontWeights } from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics';
import { useCoupon } from '@/hooks/purchase';
import { useUserEligibility } from '@/hooks/user/useUserEligibility';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import messages from '@/messages/messages';

import { Wrapper } from './styles';

const HeaderButtonCta = ({
	isOpaque,
	isMobileMenuOpen,
}: {
	isOpaque: boolean;
	isMobileMenuOpen: boolean;
}) => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const routerPush = useRouterPush();
	const { isCoupon } = useCoupon();
	const { isLoggedOut, isLoggedInEligibleForTrial, isLoggedInEligibleToSubscribe, isLoggedInValid } =
		useUserEligibility();
	const { isEnrolled: isFTUESignupEnrolled } = useAmplitudeExperiment('www-skip-questionnaire');

	const buttonTextColor = isOpaque ? 'white' : 'gray7';
	const buttonBgColor = isOpaque ? 'buttonPrimaryBg' : 'white';

	const getButtonProperties = (): [
		href: { pathname: string; queryParams?: ParsedUrlQueryInput },
		label: string,
	] => {
		if (isLoggedInValid) {
			return [{ pathname: '/app' }, formatMessage(messages.continueToApp)];
		}

		if (isLoggedInEligibleToSubscribe || isCoupon) {
			return [{ pathname: '/subscribe' }, formatMessage(messages.subscribeToCalm)];
		}

		return [
			{
				pathname: '/signup-flow',
				queryParams: { source: 'homepage', ...(isFTUESignupEnrolled && { utm_content: 'organic_ft-sf' }) },
			},
			formatMessage(messages.tryCalm),
		];
	};

	const [{ pathname, queryParams }, label] = getButtonProperties();

	const onFreeTrialClick = async () => {
		logEvent({
			eventName: 'Homepage : NavHeader : Try Calm for Free : Clicked',
		});

		if (isLoggedOut || isLoggedInEligibleForTrial) {
			logEvent({
				eventName: 'Landing Page : Signup Flow CTA : Clicked',
			});
		}

		await routerPush(pathname, queryParams);
	};

	return (
		<Wrapper
			tabIndex={isMobileMenuOpen ? -1 : 0}
			fullWidth
			fontWeight={FontWeights.Demi}
			backgroundColor={buttonBgColor}
			textColor={buttonTextColor}
			onPress={onFreeTrialClick}
			focusRingVariant={isOpaque ? FocusRingVariants.Dark : FocusRingVariants.Light}
		>
			{adoptOrphans(label, 3)}
		</Wrapper>
	);
};

export default HeaderButtonCta;
