import FocusLock from 'react-focus-lock';
import styled from 'styled-components';
import { theme } from 'styled-tools';

import { Button, IconButton, minWidth } from '@calm-web/design-system';
import { ProfileOutline } from '@calm-web/icons';

export const FocusWrapper = styled(FocusLock)`
	width: 100%;
`;

export const Wrapper = styled.nav`
	align-items: center;
	display: flex;
	list-style: none;
	justify-content: space-between;
	padding: 0;
	width: 100%;
`;

const HAMBURGER_MENU_MAX_WIDTH = 1299;

export const NavWrapper = styled.ul`
	align-items: center;
	display: flex;
	list-style: none;
	padding: 0;
	column-gap: 24px;

	@media screen and (min-width: 1550px) {
		column-gap: 48px;
	}

	@media screen and (max-width: ${HAMBURGER_MENU_MAX_WIDTH}px) {
		&:first-of-type {
			& li:not(:first-child) {
				display: none;
			}
		}
	}
`;

export const LogoWrapper = styled.li`
	height: 60px;
	position: relative;
	text-align: center;
	width: 76px;
`;
export const LogoLink = styled(Button)`
	&&& {
		height: 25px;
		width: 64px;

		${minWidth('desktop')} {
			height: 60px;
			width: 85px;
		}
	}
`;

export const NavItemListElement = styled.li`
	display: none;
	flex-shrink: 0;

	${minWidth('desktop')} {
		display: block;
	}
`;
export const IconWrapper = styled.span<{
	$isVisible: boolean;
	$screenSize?: 'mobile' | 'desktop';
	$height?: number;
	$width?: number;
}>`
	display: ${p => {
		switch (p.$screenSize) {
			case 'desktop':
				return 'none';
			case 'mobile':
				return 'block';
			default:
				return 'block';
		}
	}};
	height: 25px;
	width: 64px;
	left: 50%;
	opacity: ${p => (p.$isVisible ? '1' : '0')};
	position: absolute;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
	transition: ${theme('animation.transition')};

	${minWidth('desktop')} {
		display: ${p => {
			switch (p.$screenSize) {
				case 'mobile':
					return 'none';
				case 'desktop':
					return 'block';
				default:
					return 'block';
			}
		}};
		height: ${p => (p.$height ? `${p.$height}px` : '30px')};
		width: ${p => (p.$height ? `${p.$width}px` : '75px')};
	}

	& svg {
		height: 100%;
		width: 100%;
	}
`;

export const HeaderButtonCtaWrapper = styled.li`
	flex: 0 0 180px;

	${minWidth('desktop')} {
		flex: 0 0 200px;
	}
`;

export const HambergMenuWrapper = styled.li`
	display: block;

	@media screen and (min-width: ${HAMBURGER_MENU_MAX_WIDTH + 1}px) {
		display: none;
	}
`;

export const ProfileIconWrapper = styled.li`
	display: none;
	margin-left: -18px;
	margin-right: -18px;

	${minWidth('desktop')} {
		display: block;
	}

	@media screen and (min-width: ${HAMBURGER_MENU_MAX_WIDTH + 1}px) {
		margin-right: 0;
		margin-left: -18px;
	}
`;
export const ProfileIcon = styled(IconButton).attrs({
	Icon: ProfileOutline,
})`
	width: 30px;
	height: 30px;
`;
