import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import {
	FontSizes,
	FontWeights,
	Text,
	fontSize,
	fontWeight,
	lineHeight,
	minWidth,
} from '@calm-web/design-system';

export const Wrapper = styled.section`
	overflow: hidden;
	width: 100%;
`;
export const ContentWrapper = styled.div`
	margin: 0 auto;
	max-width: 1440px;
	padding: 0 20px 60px;
	text-align: center;

	${minWidth('desktop')} {
		padding: 0 136px 100px;
	}
`;
export const Heading = styled(Text).attrs({
	el: 'h2',
	color: 'bannerText',
	noMargin: true,
	size: FontSizes.xl,
})`
	&&& {
		margin: 0 auto 32px;
		max-width: 748px;

		${minWidth('desktop')} {
			${fontSize(FontSizes['2xl'])};
			${lineHeight(FontSizes['2xl'])};
			margin: 0 auto 40px;
		}
	}
`;
export const Post = styled.article`
	background: ${palette('white')};
	border-radius: 20px;
	height: 100%;
`;
export const Thumbnail = styled.img`
	border-top-right-radius: 20px;
	border-top-left-radius: 20px;
	height: 214px;
	object-fit: cover;
	overflow: hidden;
	width: 100%;

	${minWidth('desktop')} {
		height: 300px;
	}
`;
export const Copy = styled.div`
	padding: 20px;
	text-align: left;

	${minWidth('desktop')} {
		padding: 40px 44px;
	}
`;
export const Categories = styled(Text).attrs({
	color: 'bannerText',
	size: FontSizes.sm,
	weight: FontWeights.Medium,
})`
	margin-bottom: 8px;
`;
export const PostTitle = styled(Text).attrs({
	noMargin: true,
	el: 'h3',
	styleAs: 'p',
	color: 'gray8',
})`
	&&& {
		${fontSize(FontSizes.base)}
	}
`;

export const PostLink = styled.a`
	display: block;
	position: relative;
	text-decoration: none;

	&:hover {
		${PostTitle} {
			color: ${palette('headerBlue')};
		}
	}

	&:after {
		border: 2px ${palette('headerBlue')} solid;
		border-radius: 20px;
		content: '';
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transform: scale(1);
		transition: ${theme('animation.transition')};
	}

	&:focus {
		outline: none;
		&:after {
			opacity: 1;
			transform: scale(1.05);
		}
	}
`;
export const ViewAllCta = styled.a`
	color: ${palette('black')};
	display: inline-block;
	${fontSize(FontSizes.base)}
	${fontWeight(FontWeights.Demi)}
	margin: 0 auto;

	${minWidth('desktop')} {
		transform: translateY(-56px);
	}
`;
