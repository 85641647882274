import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { Button, FocusRingVariants, FontWeights } from '@calm-web/design-system';

import Image from '@/components/image/NextImage';
import PlanSkuItems from '@/components/purchase/PlanSkuToggle/PlanSkuItems';
import { useAnalytics } from '@/hooks/analytics';
import { useFormattedCurrency } from '@/hooks/purchase';
import { usePlanSkuToggle } from '@/hooks/purchase/usePlanSkuToggle';
import { useDeviceState, usePricesState } from '@/hooks/store';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import messages from '@/messages/messages';
import isUSPricing from '@/utils/prices/isUSPricing';

import _messages from './messages';
import { BgContainer, Wrapper, Container, Heading, Copy } from './styles';

const PlanToggle = ({
	backgroundUrl,
	analyticsPrefix = 'Homepage',
	fadeBg = true,
}: {
	backgroundUrl: string;
	fadeBg?: boolean;
	analyticsPrefix?: string;
}) => {
	const { activePlan, setActivePlan, skus } = usePlanSkuToggle();
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const { query } = useRouter();
	const prices = usePricesState();
	const formatCurrency = useFormattedCurrency();
	const routerPush = useRouterPush();
	const device = useDeviceState();

	const onClick = async () => {
		logEvent({
			eventName: `${analyticsPrefix} : CTA : Selected`,
			eventProps: {
				section: 'try_calm',
				selected_plan: activePlan,
			},
		});

		// Used in UA Tracking
		logEvent({
			eventName: 'Landing Page : Signup Flow CTA : Clicked',
		});

		await routerPush('/signup-flow', {
			source: 'homepage',
			plan: activePlan,
		});
	};

	const price =
		activePlan === 'monthly'
			? formatCurrency(prices?.current?.monthly)
			: formatCurrency(prices?.current?.yearly);

	const dontEnrollInPricingTest = !isUSPricing(prices, device) || query?.utm_medium === 'paid';

	const visibleSkus = !dontEnrollInPricingTest ? skus : skus.filter(sku => sku.id !== 'monthly');

	return (
		<Wrapper>
			<BgContainer $fadeBg={fadeBg}>
				<Image
					alt=""
					loading="lazy"
					layout="fill"
					objectFit="cover"
					objectPosition="bottom center"
					src={backgroundUrl}
				/>
			</BgContainer>
			<Container>
				<Heading>
					{formatMessage(activePlan === 'yearly' ? _messages.heading : _messages.monthlyHeading)}
				</Heading>
				<PlanSkuItems skus={visibleSkus} setActivePlan={setActivePlan} />
				<Copy>
					{formatMessage(activePlan === 'yearly' ? _messages.yearlyBody : _messages.monthlyBody, {
						price,
					})}
					&nbsp;
					<a href="/terms" target="_blank" rel="noreferrer">
						{formatMessage(messages.terms)}
					</a>
					&nbsp;|&nbsp;
					<a
						href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
						target="_blank"
						rel="noreferrer"
					>
						{formatMessage(messages.cancelAnytime)}
					</a>
				</Copy>
				<Button
					fullWidth
					onPress={onClick}
					fontWeight={FontWeights.Demi}
					backgroundColor="buttonPrimaryBg"
					textColor="white"
					focusRingVariant={FocusRingVariants.Dark}
				>
					{formatMessage(messages.continue)}
				</Button>
			</Container>
		</Wrapper>
	);
};

export default PlanToggle;
