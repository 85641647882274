import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import { useIntl } from 'react-intl';

import { CalmAppLogo, ChevronRight } from '@calm-web/icons';

import { useAnalytics } from '@/hooks/analytics';
import { useDeviceState } from '@/hooks/store';
import { useRouterPush } from '@/hooks/utils/useRouterPush';

import messages from './messages';
import { Wrapper, Dialog, Links, Link, ButtonLink, IconCtaWrapper, Cta, BrowserIcon } from './styles';
import {
	APP_INSTALL_ACTION_SHEET_SESSION_KEY,
	canShowAppInstallActionSheet,
	getBrowserSpecificIconProperties,
} from './utils';

const CONTINUE_IN_APP_URL = 'https://calm.onelink.me/Pf5F/oislygnz';

const AppInstallActionSheet = () => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const { query } = useRouter();
	const device = useDeviceState();
	const routerPush = useRouterPush();

	const [shouldRender, setShouldRender] = useState(true);
	const [isVisible, setIsVisible] = useState(false);

	const onContinueInBrowser = () => {
		logEvent({
			eventName: 'App Install Drawer : Clicked',
			eventProps: {
				options: 'Continue in browser',
				platform: device?.os || null,
			},
		});

		// Animate out
		setIsVisible(false);

		setTimeout(() => {
			setShouldRender(false);
		}, 300);
	};

	const onContinueInApp = async () => {
		logEvent({
			eventName: 'App Install Drawer : Clicked',
			eventProps: {
				options: 'Continue in app',
				platform: device?.os || null,
			},
		});

		await routerPush(CONTINUE_IN_APP_URL);
	};

	// Set whether action sheet should appear and animate in
	useEffect(() => {
		const shouldShow = canShowAppInstallActionSheet(query, device);
		setShouldRender(shouldShow);

		if (shouldShow) {
			logEvent({
				eventName: 'App Install Drawer : Shown',
			});
			sessionStorage.setItem(APP_INSTALL_ACTION_SHEET_SESSION_KEY, 'true');
			setIsVisible(true);
		}
	}, [device, query, logEvent]);

	const deviceSpecificIconProperties = getBrowserSpecificIconProperties(device);

	if (!shouldRender) {
		return null;
	}

	return (
		<Wrapper $isVisible={isVisible}>
			<ReactFocusLock returnFocus autoFocus={false}>
				<Dialog $isVisible={isVisible} role="dialog" aria-label={formatMessage(messages.dialogAriaLabel)}>
					<Links>
						<Link onClick={onContinueInApp} href={CONTINUE_IN_APP_URL}>
							<IconCtaWrapper>
								<CalmAppLogo width={64} height={64} />
								<Cta>{formatMessage(messages.continueInApp)}</Cta>
							</IconCtaWrapper>
							<ChevronRight width={24} height={24} />
						</Link>
						<ButtonLink onClick={onContinueInBrowser}>
							<IconCtaWrapper>
								<BrowserIcon
									as={deviceSpecificIconProperties.icon}
									width={64}
									height={64}
									aria-label={formatMessage(deviceSpecificIconProperties.ariaLabel)}
								/>
								<Cta>{formatMessage(messages.continueInBrowser)}</Cta>
							</IconCtaWrapper>
							<ChevronRight width={24} height={24} />
						</ButtonLink>
					</Links>
				</Dialog>
			</ReactFocusLock>
		</Wrapper>
	);
};

export default AppInstallActionSheet;
