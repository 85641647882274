import { MessageDescriptor, useIntl } from 'react-intl';

import { useAnalytics } from '@/hooks/analytics';
import { useLocale } from '@/hooks/locale/useLocale';
import { BlogPost } from '@/utils/blogPosts';

import HomepageCarousel from '../HomepageCarousel';
import messages from './messages';
import {
	Wrapper,
	ContentWrapper,
	Heading,
	Post,
	PostLink,
	Thumbnail,
	Copy,
	Categories,
	PostTitle,
	ViewAllCta,
} from './styles';

const BlogFeed = ({
	blogPosts,
	heading,
	analyticsPrefix = 'Homepage',
}: {
	blogPosts: BlogPost[];
	heading: MessageDescriptor;
	analyticsPrefix?: string;
}) => {
	const { calmLanguage } = useLocale();
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const visiblePosts = blogPosts.filter((_, i) => i < 20);

	if (calmLanguage !== 'en') return null;
	if (visiblePosts.length === 0) return null;

	const onPostClick = (post: BlogPost) => {
		logEvent({
			eventName: `${analyticsPrefix} : CTA : Clicked`,
			eventProps: {
				section: 'blog_feed',
				post_name: post.title,
			},
		});
	};

	const onViewAllClick = () => {
		logEvent({
			eventName: `${analyticsPrefix} : CTA: Clicked`,
			eventProps: {
				section: 'blog_feed',
				copy: messages.cta.defaultMessage,
			},
		});
	};

	return (
		<Wrapper>
			<ContentWrapper>
				<Heading>{formatMessage(heading)}</Heading>
				<HomepageCarousel
					slideItems={visiblePosts.map(post => ({
						key: post.id,
						Slide: ({ isActive }) => (
							<Post>
								<PostLink href={post.fullUrl} onClick={() => onPostClick(post)} tabIndex={isActive ? 0 : -1}>
									<Thumbnail loading="lazy" src={post.assetUrl} alt={`Thumbnail for ${post.title}`} />
									<Copy>
										<Categories>{post.categories.join(', ')}</Categories>
										<PostTitle dangerouslySetInnerHTML={{ __html: post.title }} />
									</Copy>
								</PostLink>
							</Post>
						),
					}))}
					numSlides={{
						desktopLarge: 2,
						desktop: 2,
						tablet: 2,
						mobile: 1,
					}}
					slideGap={{
						desktopLarge: 48,
						desktop: 36,
						tablet: 16,
						mobile: 8,
					}}
				/>
				<ViewAllCta onClick={onViewAllClick} href="https://calm.com/blog">
					{formatMessage(messages.cta)}
				</ViewAllCta>
			</ContentWrapper>
		</Wrapper>
	);
};

export default BlogFeed;
