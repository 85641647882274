import { defineMessages } from 'react-intl';

export default defineMessages({
	heading: {
		id: 'homepage.planToggle.heading2',
		defaultMessage: 'Start your free trial of Calm Premium.',
		description: 'Heading for section that lets user select Calm plan',
	},
	monthlyHeading: {
		id: 'homepage.planToggle.monthlyHeading2',
		defaultMessage: 'Get Started with Calm Premium.',
		description: 'The heading for if a user has selected the monthly plan to purchase',
	},
	yearlyBody: {
		id: 'homepage.planToggle.yearly.body',
		defaultMessage:
			'After your free trial, the yearly subscription is {price} and automatically renews each year until cancelled.',
		description: 'Explanation for how charge works after free plan ends',
	},
	monthlyBody: {
		id: 'homepage.planToggle.monthly.body',
		defaultMessage:
			'The monthly subscription is {price} and automatically renews each month until cancelled.',
		description: 'Explanation for how charge works after free plan ends',
	},
});
