import { Device } from '@/store/device/types';
import { FeatureFlags } from '@/store/feature-flags/types';
import { User } from '@/store/user/types';
import apiRequest from '@/utils/apiRequest';

export const shouldCheckTermsStatus = (user: User, flagValues: FeatureFlags) => {
	const b2b_details = user.subscription?.b2b_details;
	const isB2BTermsEnabled = flagValues['b2b-terms-and-conditions'];
	const shouldCheckTerms = isB2BTermsEnabled && b2b_details?.partner_slug === 'kp';
	return shouldCheckTerms;
};

export const shouldShowToS = async (user: User) => {
	const token = user?.token;

	try {
		if (token) {
			const result: {
				data: Device;
			} = await apiRequest({
				endpoint: 'device',
				method: 'GET',
				customHeaders: { 'x-session-token': token },
			});

			const hasToSCta = result?.data?.call_to_action?.name === 'b2b_tos';
			return hasToSCta;
		}
	} catch (error) {
		// eslint-disable-next-line
		console.log('Header -> shouldShowToS -> device -> error', error);
	}
	return false;
};
