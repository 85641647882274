import { ParsedUrlQuery } from 'querystring';

import { BrowserLogo, ChromeLogo, SafariLogo } from '@calm-web/icons';

import { Device } from '@/store/device/types';

import messages from './messages';

export const APP_INSTALL_ACTION_SHEET_SESSION_KEY = 'appInstallActionSheetSeen';

export const canShowAppInstallActionSheet = (query: ParsedUrlQuery, device: Device): boolean => {
	const isOrganic = query?.utm_medium !== 'paid';

	const isMobile = Boolean(device.isMobile);
	const hasBeenSeenThisSession =
		typeof window !== 'undefined'
			? sessionStorage.getItem(APP_INSTALL_ACTION_SHEET_SESSION_KEY) === 'true'
			: false;

	return isOrganic && isMobile && !hasBeenSeenThisSession;
};

export const getBrowserSpecificIconProperties = (device: Device) => {
	if (device.os === 'iOS') {
		return {
			icon: SafariLogo,
			ariaLabel: messages.safariLogoAriaLabel,
		};
	} else if (device.os === 'AndroidOS') {
		return {
			icon: ChromeLogo,
			ariaLabel: messages.chromeLogoAriaLabel,
		};
	} else {
		return {
			icon: BrowserLogo,
			ariaLabel: messages.browserLogoAriaLabel,
		};
	}
};
