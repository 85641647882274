import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, FontWeights, Text, TextButton } from '@calm-web/design-system';

import { APP_INSTALL_ACTION_SHEET_INDEX } from '@/utils/ui/zIndices';

export const Wrapper = styled.div<{ $isVisible: boolean }>`
	background: rgba(0, 0, 0, 0.4);
	inset: 0;
	opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
	position: fixed;
	transition: 0.3s ease-in-out;
	z-index: ${APP_INSTALL_ACTION_SHEET_INDEX};
`;

export const Dialog = styled.div<{ $isVisible: boolean }>`
	background: ${palette('white')};
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	inset: auto 0 0 0;
	padding: 4px 20px;
	position: fixed;
	transition: 0.3s ease-in-out;
	transform: translate3d(0, ${({ $isVisible }) => ($isVisible ? 0 : '100%')}, 0);
	z-index: ${APP_INSTALL_ACTION_SHEET_INDEX + 1};
`;

export const Links = styled.div`
	display: grid;
`;

export const Link = styled.a`
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: space-between;
	padding: 16px 0;
	text-decoration: none;
`;

export const ButtonLink = styled(TextButton)`
	&& {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 16px 0;
		text-decoration: none;
	}
`;

export const IconCtaWrapper = styled.div`
	align-items: center;
	display: flex;
`;

export const Cta = styled(Text).attrs({
	color: 'black',
	size: FontSizes.base,
	weight: FontWeights.Demi,
})`
	margin-left: 16px;
`;

export const BrowserIcon = styled.div`
	border: 1px rgba(0, 0, 0, 0.2) solid;
	border-radius: 12px;
	padding: 6px;
`;
