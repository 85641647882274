import styled from 'styled-components';

export const Wrapper = styled.ul<{ $height: string }>`
	height: ${p => p.$height};
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 0;
	width: 100%;
`;

export const Slide = styled.li<{ $leftOffset: string; $height: string; $width: string }>`
	height: ${p => p.$height};
	position: absolute;
	top: 0;
	left: 0;
	transform: translateX(${p => p.$leftOffset});
	transition: transform 0.2s ease-in-out;
	width: ${p => p.$width};
`;

export const SlideInner = styled.div<{ $height: string }>`
	height: ${p => p.$height};
`;
