import Link from 'next/link';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FocusRingVariants } from '@calm-web/design-system';
import { AppIcon, CalmLogo, CalmLogoGradient } from '@calm-web/icons';

import HamburgerMenu from '@/components/header/HamburgerMenu';
import { AuthModalContext } from '@/context/AuthModalContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useUserEligibility } from '@/hooks/user/useUserEligibility';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import messages from '@/messages/messages';
import { setAuthFormMode } from '@/store/authFormMode/actions';

import HeaderButtonCta from '../HeaderButtonCta';
import MobileMenu from '../MobileMenu';
import NavItemLink from '../NavItemLink';
import { HeaderNavItem } from '../NavItemLink/types';
import { SCREENING_NAV_ITEM, leftNavItems, rightNavItems } from './data';
import _messages from './messages';
import {
	Wrapper,
	NavWrapper,
	LogoWrapper,
	IconWrapper,
	NavItemListElement,
	HeaderButtonCtaWrapper,
	ProfileIconWrapper,
	ProfileIcon,
	HambergMenuWrapper,
	LogoLink,
	FocusWrapper,
} from './styles';

const NavItems = ({
	isOpaque,
	onNavItemClick,
}: {
	isOpaque: boolean;
	onNavItemClick: (navItem: HeaderNavItem) => Promise<void>;
}) => {
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();
	const { setIsModalOpen } = useContext(AuthModalContext);
	const { formatMessage } = useIntl();
	const { isLoggedOut, isLoggedInValid } = useUserEligibility();
	const { isEnrolled } = useAmplitudeExperiment('www-screening-header-nav');
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const onMenuToggle = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	const onLoginClick = () => {
		logEvent({ eventName: 'Homepage : NavHeader : Login : Clicked' });
		dispatch(setAuthFormMode('login'));
		if (setIsModalOpen) {
			setIsModalOpen(true);
		}
	};

	return (
		<FocusWrapper disabled={!isMobileMenuOpen}>
			<Wrapper aria-label="primary">
				<NavWrapper>
					<LogoWrapper>
						<Link href="/" legacyBehavior>
							<>
								<LogoLink
									hideShadow
									href="/"
									backgroundColor="transparent"
									aria-label={formatMessage(_messages.logoAriaLabel)}
									tabIndex={isMobileMenuOpen ? -1 : 0}
									focusRingVariant={isOpaque ? FocusRingVariants.Dark : FocusRingVariants.Light}
								>
									<IconWrapper $isVisible={!isOpaque} $screenSize="desktop" $width={56} $height={56}>
										<AppIcon width={56} height={56} />
									</IconWrapper>
									<IconWrapper $isVisible={isOpaque}>
										<CalmLogoGradient />
									</IconWrapper>
									<IconWrapper $isVisible={!isOpaque} $screenSize="mobile">
										<CalmLogo />
									</IconWrapper>
								</LogoLink>
							</>
						</Link>
					</LogoWrapper>
					{leftNavItems.map(navItem => (
						<NavItemListElement key={navItem.href}>
							<NavItemLink isOpaque={isOpaque} onClick={() => onNavItemClick(navItem)} navItem={navItem} />
						</NavItemListElement>
					))}
				</NavWrapper>
				<NavWrapper>
					{(isEnrolled ? [SCREENING_NAV_ITEM, ...rightNavItems] : rightNavItems).map(navItem => (
						<NavItemListElement key={navItem.href}>
							<NavItemLink isOpaque={isOpaque} onClick={() => onNavItemClick(navItem)} navItem={navItem} />
						</NavItemListElement>
					))}

					{isLoggedOut && (
						<NavItemListElement>
							<NavItemLink
								ariaLabel="Log In"
								testId="desktopnav-login-button"
								isOpaque={isOpaque}
								onClick={onLoginClick}
								navItem={{ label: messages.loginButton }}
							/>
						</NavItemListElement>
					)}
					{!isLoggedOut && !isLoggedInValid && (
						<NavItemListElement>
							<NavItemLink
								isOpaque={isOpaque}
								onClick={() => onNavItemClick({ label: messages.continueToApp, href: '/app' })}
								navItem={{ label: messages.continueToApp, href: '/app' }}
							/>
						</NavItemListElement>
					)}
					<HeaderButtonCtaWrapper>
						<HeaderButtonCta isMobileMenuOpen={isMobileMenuOpen} isOpaque={isOpaque} />
					</HeaderButtonCtaWrapper>
					{!isLoggedOut && (
						<ProfileIconWrapper>
							<ProfileIcon
								color={isOpaque ? 'gray7' : 'white'}
								aria-label={formatMessage(messages.profile)}
								href="/app/profile"
							/>
						</ProfileIconWrapper>
					)}
					<HambergMenuWrapper>
						<HamburgerMenu onClick={onMenuToggle} isDark={!isOpaque} isOpen={isMobileMenuOpen} />
					</HambergMenuWrapper>
				</NavWrapper>
			</Wrapper>
			<MobileMenu
				isMobileMenuOpen={isMobileMenuOpen}
				setIsMobileMenuOpen={setIsMobileMenuOpen}
				onNavItemClick={onNavItemClick}
				onLoginClick={onLoginClick}
			/>
		</FocusWrapper>
	);
};

export default NavItems;
