import { useRouter } from 'next/router';

import AuthModal from '@/components/authForm/AuthModal';
import { getPodcastRedirectUrl } from '@/components/campaign';
import { useAuthFormModeState, useUserState, usePricesState } from '@/hooks/store';
import { useFeatureFlags } from '@/hooks/utils/useFeatureFlags';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { User } from '@/store/user/types';
import isValidLifetimeCoupon from '@/utils/prices/isValidLifetimeCoupon';
import { userCanTrial } from '@/utils/subscriptions';

import { shouldCheckTermsStatus, shouldShowToS } from './utils';

const HeaderAuthModal = () => {
	const routerPush = useRouterPush();
	const authFormMode = useAuthFormModeState();
	const user = useUserState();
	const prices = usePricesState();
	const { query } = useRouter();
	const { flagValues } = useFeatureFlags('b2b-terms-and-conditions');

	const onAuthSuccess = async (_user: User): Promise<void> => {
		if (query.utm_medium === 'podcast') {
			const hasLifeTimeUpsellCoupon = isValidLifetimeCoupon(prices);
			const podcastRedirectUrl = getPodcastRedirectUrl(user, prices, hasLifeTimeUpsellCoupon);

			await routerPush(podcastRedirectUrl);
		} else if (authFormMode === 'signup' && userCanTrial(_user)) {
			await routerPush('/signup-flow');
		} else if (shouldCheckTermsStatus(_user, flagValues)) {
			const showToS = await shouldShowToS(_user);
			await routerPush(showToS ? '/b2b/tos' : '/app');
		} else {
			await routerPush('/app');
		}
	};

	return <AuthModal onAuthSuccess={onAuthSuccess} />;
};

export default HeaderAuthModal;
