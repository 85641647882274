import { userCanSubscribe, userCanTrial } from '@/utils/subscriptions';

import { useUserState } from '../store';

export const useUserEligibility = () => {
	const user = useUserState();

	const isLoggedOut = !user?.token;
	const isLoggedInEligibleForTrial = !isLoggedOut && userCanTrial(user);
	const isLoggedInEligibleToSubscribe = !isLoggedOut && !isLoggedInEligibleForTrial && userCanSubscribe(user);
	const isLoggedInValid = !isLoggedOut && user?.subscription?.valid;

	return {
		isLoggedOut,
		isLoggedInEligibleForTrial,
		isLoggedInEligibleToSubscribe,
		isLoggedInValid,
	};
};
