import { MessageDescriptor, useIntl } from 'react-intl';

import Image from '@/components/image/NextImage';
import FiveStarMessages from '@/components/ui/StarRating/messages';

import HomepageCarousel from '../HomepageCarousel';
import FiveStars from './5-stars.svg';
import messages from './messages';
import QuoteIcon from './quote-icon.svg';
import { Wrapper, ContentWrapper, Heading, Logos, LogoItem, ReviewItem, Quote, Author } from './styles';
import { Logo, Review } from './types';

export type TestimonialsProps = {
	logos?: Logo[];
	reviews: Review[];
	reviewsHeading: MessageDescriptor;
};

const Testimonials = ({ logos = [], reviews, reviewsHeading }: TestimonialsProps) => {
	const { formatMessage } = useIntl();

	return (
		<Wrapper>
			<ContentWrapper>
				{logos.length > 0 && (
					<>
						<Heading>{formatMessage(messages.trustedHeading)}</Heading>
						<Logos>
							{logos.map(logo => (
								<LogoItem key={logo.alt} $width={logo.width}>
									<Image
										loading="lazy"
										objectFit="contain"
										objectPosition="center"
										layout="fill"
										src={logo.src}
										alt={logo.alt}
									/>
								</LogoItem>
							))}
						</Logos>
					</>
				)}
				<Heading>{formatMessage(reviewsHeading)}</Heading>
				<HomepageCarousel
					slideItems={reviews.map(review => ({
						key: formatMessage(review.author),
						Slide: () => (
							<ReviewItem>
								<QuoteIcon />
								<blockquote>
									<Quote>{formatMessage(review.quote)}</Quote>
								</blockquote>
								<Author>{formatMessage(review.author)}</Author>
								<FiveStars aria-label={formatMessage(FiveStarMessages.ariaLabel)} />
							</ReviewItem>
						),
					}))}
					numSlides={{
						desktopLarge: 3,
						desktop: 2,
						tablet: 2,
						mobile: 1,
					}}
					slideGap={{
						desktopLarge: 48,
						desktop: 36,
						tablet: 16,
						mobile: 8,
					}}
				/>
			</ContentWrapper>
		</Wrapper>
	);
};

export default Testimonials;
