import styled from 'styled-components';

import { IconButton, minWidth, units } from '@calm-web/design-system';

import ChevronLeft from 'icons/chevron-left-aligned.svg';

export const Arrow = styled(IconButton).attrs({
	Icon: ChevronLeft,
	color: 'white',
})`
	&&& {
		background: ${p => (p.isDisabled ? 'rgba(0, 0, 0,  0.2)' : 'rgba(0, 0, 0, 0.4)')};
		height: ${units(4)};
		margin-left: ${units(1)};
		margin-right: ${units(1)};
		width: ${units(4)};
		min-height: unset;
		min-width: unset;
		padding: 0;
		div {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		& svg {
			height: 20px;
		}
	}
`;

export const Wrapper = styled.ul`
	margin: 0;
	padding: 0;
	align-items: center;
	display: flex;
	justify-content: center;
	list-style-type: none;
	margin-bottom: ${units(3)};
	margin-top: ${units(3)};

	${minWidth('desktop')} {
		justify-content: flex-end;
	}

	li:last-of-type {
		${Arrow} {
			transform: rotate(180deg);
		}
	}
`;
