import { defineMessages } from 'react-intl';

const messages = defineMessages({
	ariaLabel: {
		id: 'ui.starRating.ariaLabel',
		defaultMessage: 'Rating 5 star',
		description: 'Aria label for 5 star rating',
	},
});

export default messages;
